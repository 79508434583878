<template>
  <div>
    <div v-if="!!callItem && activityCounterparts && activityCounterparts.length > 0"
         class="mt-3"
    >
      <div class="d-flex">
        <div class="row col-3 mt-3">
          {{ staticText.subjectLabel }}
        </div>

        <div class="w-100">
          <b-form-input v-model="subject"
                        :placeholder="callItem.name"
                        :state="showSubjectError"
                        class="d-flex ml-auto mb-3"
                        type="text"
          ></b-form-input>
        </div>
      </div>

      <!-- assign event to available user -->
      <div v-if="Array.isArray(eventOwners) && eventOwners.length"
           class="d-flex mb-2"
      >
        <div class="row col-3 mt-3">
          {{ staticText.assignEventToLabel }}
        </div>

        <multiselect v-model="eventOwner"
                     :options="eventOwners"
                     :show-labels="false"
                     :allowEmpty="false"
                     track-by="id"
                     label="name"
        ></multiselect>
      </div>

      <!-- onwer detail (Name) -->
      <div class="d-flex mb-2">

        <div class="row col-3 mt-3">
          {{ staticText.counterpartNameLabel }}
        </div>
        <BaseCounterpartSelector
          v-if="activityCounterparts"
          :pre-selected-counterparts="activityCounterparts[0] || null"
          :items="activityCounterparts"
          @input="value => onOwnerChange(value)"
        />
      </div>

      <!-- related to -->
      <!-- related to can't be associated to Lead counterpart -->
      <div class="d-flex mb-2"
           v-if="!!selectedCounterpart && selectedCounterpart.type!='Lead'"
      >
        <div class="row col-3 mt-3">
          {{ staticText.relatedToLabel }}
        </div>

        <multiselect v-model="eventRelatedTo"
                     :options="crmAccountData"
                     :show-labels="false"
                     track-by="crm_id"
                     label="name"
        ></multiselect>
      </div>

      <!-- event location  -->
      <div class="d-flex mb-2">
        <div class="row col-3 mt-3">
          {{ staticText.location }}
        </div>
        <div class="w-100">
          <b-form-input v-model="location"
                        :placeholder="staticText.locationPlaceholder"
                        class="d-flex ml-auto mb-3"
                        type="text"
          ></b-form-input>
        </div>
      </div>
      <!-- start and end date -->
      <div class="d-flex">
        <VueCtkDateTimePicker
          id="crm-date-picker-202402200209"
          v-model="startDateTime"
          :label="!isAllDayEvent ? staticText.startDatetimeLabel : staticText.startDateLabel"
          :error="getStartDateTimeError"
          :format="datetimeFormat"
          :formatted="datetimeFormatted"
          :onlyDate="isAllDayEvent"
        />
        <VueCtkDateTimePicker
          id="crm-date-picker-202402200210"
          v-model="endDateTime"
          :label="!isAllDayEvent ? staticText.endDatetimeLabel : staticText.endDateLabel"
          :error="getEndDateTimeError"
          :format="datetimeFormat"
          :formatted="datetimeFormatted"
          :onlyDate="isAllDayEvent"
          :right="true"
          class="ml-5"
        />
      </div>

      <!-- all day event -->
      <div class="d-flex mt-3">
        <div class="row col-3">
          {{ staticText.allDayEventLabel }}
        </div>
        <b-form-checkbox v-model="isAllDayEvent"></b-form-checkbox>
      </div>

      <!-- create button -->
      <div class="w-100 d-flex justify-content-end">
        <ActivityCreateButton
          :is-created="created"
          :is-creating="creating"
          :button-label="staticText.createEventBtn"
          :created-label="staticText.createdEvent"
          :creating-label="staticText.creatingEvent"
          :disabled="isPreviewMode"
          @create="createEvent"
        />
      </div>
    </div>

    <div v-else>
      <base-alert
        id="no-counterpart-alert-202402200212"
        :alert-message="staticText.alertText"
        :show-alert="true"
        variant="danger"
        class="mt-3"
      ></base-alert>
    </div>
  </div>
</template>

<script>
import { ActivityCreatorMixin } from "@/apps/call/CallViewItem_components/ActivityCreatorMixin"

export default {
  name: "CrmEventCreator",
  mixins: [ActivityCreatorMixin],
  props: {
    callItem: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      staticTextDefault: {
        createEventBtn: "Create CRM Event",
        startDatetimeLabel: "Select start date & time",
        endDatetimeLabel: "Select end date & time",
        startDateLabel: "Select start date",
        endDateLabel: "Select end date",
        createdEvent: "Event created",
        creatingEvent: "Creating event ...",
        assignEventToLabel: "Assign event to",
        alertText: "To create a CRM event select a counterpart in the top-right corner.",
        eventStartDateLabel: "Event Start Date",
        eventEndDateLabel: "Event End Date",
        assignedToLabel: "Assigned to",
        counterpartLabel: "Counterpart",
        linkToActivityLabel: "Link to Event",
        location: "Location",
        locationPlaceholder: "Event Location",
        allDayEventLabel: "All Day Event",
        relatedToLabel: "Related To",
        counterpartNameLabel: "Name",
        warningMessage: "Error in creating CRM Event."
      },
      eventOwners: null,
      eventOwner: null,
      dateError: false,
      eventAssigneeError: false,
      startDateTime: null,
      endDateTime: null,
      isAllDayEvent: false,
      location: null,
      eventRelatedTo: null,
      crmAccountData: []

    }
  },
  computed: {
    datetimeFormat () {
      if (!this.isAllDayEvent) return "YYYY-MM-DD HH:mm"
      return "YYYY-MM-DD"
    },
    datetimeFormatted () {
      if (!this.isAllDayEvent) return "lll"
      return "ll"
    },
    getStartDateTimeError () {
      return !(this.isAllDayEvent || this.startDateTime) ? this.dateError : false
    },
    getEndDateTimeError () {
      return !(this.isAllDayEvent || this.endDateTime) ? this.dateError : false
    }
  },

  async mounted () {
    this.itemNotes = this.callItem.notes
    this.subject = this.callItem.name
    this.selectedCounterpart = !!this.activityCounterparts && !!this.activityCounterparts.length ? this.activityCounterparts[0] : null
    if (this.selectedCounterpart) {
      await this.getActivityUsers(this.selectedCounterpart.crm_service).then(() => {
        this.getPreselection()
        // get crm data of type "account" for "Related To" field
        // related to can't be associated to Lead counterpart
        if (this.selectedCounterpart.type !== "Lead") {
          this.getCrmObject("Account")
        }
      })
    }
  },

  watch: {
    activityCounterparts: {
      async handler (val) {
        if (!this.selectedCounterpart) {
          this.selectedCounterpart = val[0]
          if (this.selectedCounterpart) {
            await this.getActivityUsers(this.selectedCounterpart.crm_service).then(() => {
              this.getPreselection()
              if (this.selectedCounterpart.type !== "Lead") {
                this.getCrmObject("Account")
              }
            })
          }
        }
      },
      deep: true
    }
  },
  methods: {
    getPreselection () {
      if (this.users) {
        this.eventOwners = this.users
        this.eventOwner = !!this.eventOwners && !!this.eventOwners.length ? this.eventOwners[0] : null
      }
    },
    async getCrmObject (dataType) {
      // get crm data of the counterpart for "Related To" field
      try {
        if (!(this.selectedCounterpart && this.selectedCounterpart.external_resource && this.selectedCounterpart.external_resource.AccountId)) {
          // don't call api if AccountId is not available of counterpart
          return
        }
        const url = "/api/counterpartsnew/get_counterpart?service=" + this.selectedCounterpart.crm_service
        const data = { type: dataType, crm_id: this.selectedCounterpart.external_resource.AccountId }
        const apiData = await this.axios.post(url, data)
        this.crmAccountData = [apiData.data]
        this.eventRelatedTo = apiData.data
      } catch (error) {
        this.showWarningMessage("Something went wrong", "Error in fetching related to data.")
      }
    },

    onOwnerChange (val) {
      // get related to data on change in selected owner
      this.selectedCounterpart = val
      if (this.selectedCounterpart.type !== "Lead") {
        this.getCrmObject("Account")
      }
    },
    checkErrors () {
      if (!(this.startDateTime && this.endDateTime)) {
        this.dateError = true
        this.showWarningMessage("Event Date Not Selected", "Event Date is required field")
        return true
      }
      if (!this.subject) {
        this.showWarningMessage("Subject is empty", "Subject is required field")
        return true
      }
      if (new Date(this.startDateTime) > new Date(this.endDateTime)) {
        this.showWarningMessage("Invalid start and end date", "Event Start date should be less than event end date")
        return true
      }
      if ((new Date(this.endDateTime) - new Date(this.startDateTime)) / (1000 * 60 * 60 * 24) > 14) {
        this.showWarningMessage("Invalid event date", "An event can't last longer than 14 days")
        return true
      }
      return false
    },
    async createEvent () {
      if (this.checkErrors()) return

      // event start data, end date, subject, event assigned to are mandatory fields for event. so check those fields before hitting the api
      const datetimeFormat = this.isAllDayEvent ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm"
      const eventStart = this.moment.utc(new Date(this.startDateTime)).format(datetimeFormat)
      const eventEnd = this.moment.utc(new Date(this.endDateTime)).format(datetimeFormat)

      const payload = {
        subject: this.subject,
        startDate: eventStart,
        endDate: eventEnd,
        location: this.location,
        isAllDayEvent: !!this.isAllDayEvent,
        eventRelatedToId: this.eventRelatedTo ? this.eventRelatedTo.crm_id : "",
        counterpartType: this.selectedCounterpart.type,
        counterpartCrmId: this.selectedCounterpart.crm_id,
        counterpartName: this.selectedCounterpart.name

      }
      this.itemNotes = this.callItem.notes
      payload.text = this.itemNotes ? this.utils.strip(this.itemNotes) : ""
      if (this.eventOwner) {
        payload.eventAssigneeId = this.eventOwner.id
      } else {
        this.eventAssigneeError = true
        this.showWarningMessage("Event Assignee not selected", "Event Assignee is required field")
        return
      }

      await this.createActivity("event", payload)
    },
    getNotesToUpdate () {
      let notes = ""
      notes += this.eventOwner ? `<p>${this.staticText.assignedToLabel}: ${this.eventOwner.name}</p>` : ""
      notes += `<p>${this.staticText.eventStartDateLabel}: ${this.startDateTime}</p>`
      notes += `<p>${this.staticText.eventEndDateLabel}: ${this.endDateTime}</p>`
      notes += `<p>${this.staticText.counterpartLabel}: ${this.selectedCounterpart.name}</p>`
      return notes
    }
  }
}
</script>
